import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import JSONData from "../data/exhibitions.json"
import ExhibitionsList from "../components/exhibitionsList"

const Exhibitions = (props) => {

    return (
        <Layout>
            <Seo title="Exhibitions" />
            <h2>Exhibitions</h2>
            <h3>Solo Exhibitions</h3>
            <ExhibitionsList exhibitions={JSONData.filter(exhibition => exhibition.type === 'solo')} title="Solo Exhibitions" />
            <h3>Group Exhibitions</h3>            
            <ExhibitionsList exhibitions={JSONData.filter(exhibition => exhibition.type === 'group')} title="Group Exhibitions" />


        </Layout>
    )
}

export default Exhibitions